$(document).ready(function(){
    $('.js-news-carousel').owlCarousel({
        loop: true,
        dots: true,
        autoplay: false,
        // smartSpeed: 700,
        // autoplayTimeout: 5000,
        nav: true,
        // items: 3,
        // center: true,
        navText: [$('.news-carousel__arrow-left'), $('.news-carousel__arrow-right')],
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1050: {
                items: 3
            }
        }
    });
});
