$(document).ready(function(){
    if ($('#category-list').length > 0){
        $('#category-list').selectric().on('change', function(){
            // console.log($(this).val());
            var newLocation = $(this).val();
            if ($(this).val() != '/blog/') {
                window.location.href = newLocation;
            }
        });
    }

});