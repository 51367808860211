$(document).ready(function(){

    if ($('.single-post').length > 0){
        window.onscroll = function() {updateScrollIndicator()};
    }
    // When the user scrolls the page, execute myFunction 

});

function updateScrollIndicator() {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
}
  