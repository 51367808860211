jQuery(document).ready(function($) {

    // Hook up the current state to the nav bar
    if ($('.js-page-navigation').length > 0) {
        $('.js-page-navigation').onePageNav({
            scrollChange: function($currentListItem){
                // console.log($currentListItem);
                // console.log($currentListItem[0].offsetLeft);
                $('.submenu_module .table-responsive').scrollLeft($currentListItem[0].offsetLeft);
                // animateSubmenuLeft();
            }
        });
    }
    
    $('a.submenu-item__link').on('click', function(e){
        e.preventDefault();
        var target_section = $(this).attr('href');
        $('html,body').animate({scrollTop: $(target_section).offset().top}, 'slow');
    });
    
});

var didScroll2, submenuOffset;

var submenu = jQuery('.submenu_module');
if (submenu.length > 0){
    submenuOffset = submenu.offset().top;
}


$(window).scroll(function(event){
    didScroll2 = true;
});

setInterval(function() {
    if (didScroll2) {
        hasScrolledSubmenu();
        // animateSubmenuLeft();
        didScroll2 = false;
    }
}, 250);

function hasScrolledSubmenu() {
    if (submenu.length > 0){
        var st = jQuery(document).scrollTop();
        if (st >= submenuOffset){
            submenu.addClass('sticky');
            $('main').addClass('sticky-active');
        } else {
            submenu.removeClass('sticky');
            $('main').removeClass('sticky-active');
        }
    
    }
}
function animateSubmenuLeft() {
    // var distance = ($(window).width() - $('.submenu_module .table-responsive th.current').width())/2;
    console.log('hit');
    if ($('.submenu_module .table-responsive th.current').length > 0){
        var distance = $('.submenu_module .table-responsive th.current').offset().left;

        $('.submenu_module .table-responsive').scrollLeft(distance);
    
    }
}